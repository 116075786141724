<h2 mat-dialog-title>
  Session Timeout
</h2>
<mat-dialog-content class="mat-typography">
  <h4>
    Your session will expire in 2 minutes.
    Would you like to continue your session?
  </h4>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button color="primary" (click)="logout()" mat-dialog-close>Logout</button>
  <button mat-raised-button color="primary" mat-dialog-close cdkFocusInitial>Continue Session</button>
</mat-dialog-actions>
