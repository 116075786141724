import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin, iif, merge, of } from 'rxjs';
import { NscStundentData, StudentCredentials, Credential } from '../classes/NSCStudentData';
import { DataService } from './data.service';
import { switchMap } from 'rxjs/operators';
import { map } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CredentialsService {

  CREDLY_SERVICE_ENDPOINT = '/stauth/credly/badges';
  BADGR_SERVICE_ENDPOINT = '/api/myhub/badgr/badges';
  ALL_BADGES = '/api/myhub/badges/ALL';
  credentialData = {} as StudentCredentials;


  constructor(private http: HttpClient, private dataService: DataService) {
  }

  getHomeUrl(){
    console.log ("home url:"+ window.location.href)
    return window.location.href;
  }

  refreshCredlyBadges(): Observable<any> {

    return this.http.get(this.CREDLY_SERVICE_ENDPOINT, {params: {
      callback_url: this.getHomeUrl(),
      refresh:'true'
    }});
  }

  getCredlyBadges(): Observable<any> {
    return this.http.get(this.CREDLY_SERVICE_ENDPOINT, {params: {
      callback_url: this.getHomeUrl(),
      refresh:'false'
    }});
  }

  getBadgrBadges(): Observable<any> {
    return this.http.get(this.BADGR_SERVICE_ENDPOINT, {params: {
      callbackUrl: this.getHomeUrl()
    }});
  }


  getAllBadges(): Observable<Credential[]> {
    let flag:any= localStorage.getItem("credly_new_user");
    return this.http.get<Credential[]>(this.ALL_BADGES, {params: {
      credly_new_user: flag
    }});
  }

  getWalletCredentials(): Observable<any> {
    return forkJoin([this.getBadgrBadges(),this.getCredlyBadges()]).pipe(
     switchMap(([badgrData, credlyData]) => {
     console.log('badgr api response: ',badgrData, 'credly api response: ',credlyData);
      if (badgrData.responseType === 'require_authorization') {
        this.credentialData.badgrAccountExists = false;
        this.credentialData.badgrRedirectUrl = badgrData.redirectUrl;
      } else {
       this.credentialData.badgrAccountExists = true;
      }
      if(credlyData.responseType === 'require_authorization') {
       this.credentialData.credlyAccountExists = false;
       this.credentialData.credlyRedirectUrl = credlyData.redirectUrl;
      } else {
        this.credentialData.credlyAccountExists = true;
      }
      this.dataService.saveCredentials(this.credentialData);
     if(badgrData.responseType === 'require_authorization'
      && credlyData.responseType === 'require_authorization') {
       return of(this.credentialData);
     } else {
       return this.getAllBadges();
     }
    })
    )
  }

  getMockCredlyBadges(): Observable<any> {
    return of({responseType: 'require_authorization', redirectUrl: 'https://google.com'})
  }

  getMockBadgrBadges(): Observable<any> {
    return of({responseType: 'require_authorization', redirectUrl: 'https://google.com'})
  }

}
