<div class="dashboard-container" *ngIf="isDataLoaded && smartResumeFeatureToggle">
  <div class="dashboard-header">
    <h1 id="welcomeText"> <b>Hello, {{userFirstName | titlecase }}!</b> Welcome to </h1>
    <div class="ml-2">
      <img src="assets/myhub-text.svg" alt="Myhub">
    </div>
  </div>

  <div class="small-cards-container">
    <div class="small-card">
      <mat-card id="myrec-card" role="button" routerLink="/myrecords"
      (keyup.enter)="onCardClickNoCondition('/myrecords')" aria-label="My Records. View your enrollment history, degrees earned, and credentials."
      (click)="onCardClickNoCondition('/myrecords')">
        <div style="padding: 0.5em;">
          <img id="myrecCardHeaderIcon" src="assets/my-records-new.svg" alt="" aria-hidden="true" width="100%">
        </div>
        <mat-card-header>
          <h5>
            My Records
          </h5>
        </mat-card-header>
        <mat-card-content>
          <p>
            View your enrollment history, degrees earned, and credentials.
          </p>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="small-card">
      <mat-card role="button" id="selfservice-card" routerLink="/selfservice"
      (keyup.enter)="onCardClickNoCondition('/selfservice')" aria-label="Self Service. Order transcripts, download digital diplomas, and share your credentials."
      (click)="onCardClickNoCondition('/selfservice')">
        <div style="padding: 0.5em;">
          <img id="selfservCardHeaderIcon" src="assets/self-service-new.svg" alt="" aria-hidden="true" width="100%">
        </div>
        <mat-card-header>
          <h5>
            Self Service
          </h5>
        </mat-card-header>
        <mat-card-content>
          <p>
            Order transcripts, download digital diplomas, and share your credentials.
          </p>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="small-card">
      <mat-card id="smresume-card" role="button" routerLink="/smartresume"
      (keyup.enter)="onCardClickNoCondition('/smartresume')" aria-label="Build a professional resume showcasing your verified credentials."
      (click)="onCardClickNoCondition('/smartresume')">
        <div class="my-4">
          <img id="smresumeCardHeaderIcon" src="assets/smart-resume.svg" alt="" aria-hidden="true" width="100%">
        </div>
        <mat-card-content>
          <p>
            Build a professional resume showcasing your verified credentials.
          </p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="cards-layout">
    <mat-card id="enr-card" role="button" class="card-size" aria-label="Enrollment History"
    tabindex="0" (keyup.enter)="onCardClick(hasEnrollments,'/myrecords')" (click)="onCardClick(hasEnrollments,'/myrecords')">
      <mat-card-content>
        <div class="header-container">
          <div>
            <img id="enr-cardHeaderIcon" src="assets/enrollment-history.svg" alt="" aria-hidden="true">
          </div>
          <div id="enr-cardHeaderText" class="ml-2 cursor-pointer card-title"> Enrollment History </div>
        </div>
        <div class="nodata-container" *ngIf="hasEnrollmentError">
          <app-system-error idx="enrCard"></app-system-error>
        </div>
        <div class="nodata-container" *ngIf="!hasEnrollments && !hasEnrollmentError" tabindex="0">
          <p id="noEnrText">The National Student Clearinghouse does not have records of any enrollment history at this time.
            <a id="noEnrLink" role="link" target="_blank" aria-label="How did MyHub receive my data"
              href="https://help.studentclearinghouse.org/myhub/knowledge-base/how-did-myhub-receive-my-data/">Learn
              More</a>
          </p>
        </div>
        <div class="logo-container" *ngIf="enrollmentLogos?.length && !hasEnrollmentError">
          <div *ngFor="let logo of enrollmentLogos; let i = index">
            <img id="enrCardLogo{{i+1}}" [src]="logo" alt="" aria-hidden="true">
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card id="cred-card" role="button" class="card-size" aria-label="Credentials"
    tabindex="0" (keyup.enter)="onCardClick(hasEnrollments,'/myrecords/credentials')" (click)="onCardClick(hasCredentials,'/myrecords/credentials')">
      <mat-card-content>
        <div class="header-container">
          <div>
            <img id="cred-cardHeaderIcon" src="assets/credentials.svg" alt="" aria-hidden="true">
          </div>
          <div id="cred-cardHeaderText" class="ml-2 card-title"> Credentials </div>
        </div>
        <div class="nodata-container" *ngIf="hasCredentialError">
          <app-system-error idx="credCard"></app-system-error>
        </div>

        <div class="logo-container" *ngIf="hasCredentials && credentialLogos?.length && !hasCredentialError">
          <div *ngFor="let logo of credentialLogos; let i = index">
            <img id="credCardLogo{{i+1}}" [src]="logo" alt="" aria-hidden="true" (load)="onLoaded()">
          </div>
        </div>

        <credly-badgr-login idx="credCard" [urls]="credUrls" *ngIf="!hasCredentials && !hasCredentialError"></credly-badgr-login>
      </mat-card-content>
    </mat-card>
    <mat-card id="deg-card" role="button" class="card-size" aria-label="Degrees Earned Card"
    tabindex="0" (keyup.enter)="onCardClick(hasEnrollments,'/myrecords/degrees')" (click)="onCardClick(hasDegrees,'/myrecords/degrees')">
      <mat-card-content>
        <div class="header-container">
          <div>
            <img id="degCardHeaderIcon" src="assets/degrees-earned.svg" alt="" aria-hidden="true">
          </div>
          <div id="degCardHeaderText" class="ml-2 card-title"> Degrees Earned </div>
        </div>
        <div class="nodata-container" *ngIf="hasDegreeError">
          <app-system-error idx="degCard"></app-system-error>
        </div>
        <div class="nodata-container" *ngIf="!hasDegrees && !hasDegreeError" tabindex="0">
          <p id="noDegText">The National Student Clearinghouse does not have records of any degrees earned at this time.
            <a id="noDegLink" role="link" aria-label="How did Myhub receive my data"
              href="https://help.studentclearinghouse.org/myhub/knowledge-base/how-did-myhub-receive-my-data/"
              target="_blank">Learn More</a>
          </p>
        </div>
        <div class="logo-container" *ngIf="degreeLogos?.length && !hasDegreeError">
          <div *ngFor="let logo of degreeLogos; let i = index">
            <img id="degCardLogo{{i+1}}" [src]="logo" alt="" aria-hidden="true">
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <div class="d-flex flex-column card-size">
      <mat-card id="datacorrection-card" role="button" routerLink="/datacorrection"
      (keyup.enter)="onCardClickNoCondition('/datacorrection')" aria-label="datacorrection card"
      (click)="onCardClickNoCondition('/datacorrection')" class="flex-grow-1 mb-2 h-50">
        <mat-card-content>
          <div class="header-container">
            <div style="width: 7.5%;">
              <img id="datacorrectionIcon" src="assets/data-correction-header.svg" alt="" aria-hidden="true">
            </div>
            <div id="datacorrectionCardHeader" class="ml-2 card-title"> Data Correction </div>
          </div>
          <div class="mat-body-2 mt-2 text-center" id="datacorrectionContent">
            Dispute a credential to report a data inaccuracy with the data provider.
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card role="button" id="scholarship-card" class="justify-content-center flex-grow-1 h-50" aria-label="Scholarship. Get matched to a scholarship
          with ScholarshipOwl" tabindex="0" (keyup.enter)="onEnterScholarshipDialog($event)" (click)="scholarshipDialog()">
        <mat-card-content>
          <div class="header-container" style="padding-top: 0;">
            <div style="width: 30%;">
              <img id="schshipCardIcon" src="assets/scholarship-icon.svg" alt="" aria-hidden="true">
            </div>
            <div class="mat-body-2 ml-2" style="width: unset;">
              Get matched to a scholarship with <b>ScholarshipOwl!</b>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="isDataLoaded && !smartResumeFeatureToggle">
  <app-dashboard-disputes-toggle-off></app-dashboard-disputes-toggle-off>
</div>
