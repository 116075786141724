<div class="w-100 d-flex flex-column justify-content-center flex-grow-1 pt-4">
  <div class="w-100 d-flex flex-row align-items-center row-container">
    <div>
      <img [id]="idx+'-credlyIcon'" src="assets/credly-icon.svg" alt="svg">
    </div>
    <p tabIndex="0" class="ml-3" id="credlyLoginText">If you've earned a credential on Credly,
      <a [id]="idx+'-credlyLoginLink'" class="link" role="link" aria-label="Connect your credly account" (click)="redirectToCredlyForRefresh()">connect your account.</a>
    </p>
  </div>
  <div class="d-flex flex-row align-items-center row-container">
    <div>
      <img [id]="idx+'-badgrIcon'" src="assets/badgr-icon.svg" alt="svg">
    </div>
    <p tabIndex="0" class="ml-3" id="badgrLoginText">If you've earned a credential on Badgr,
      <a [id]="idx+'-badgrLoginLink'" role="link" aria-label="Connect your Badgr account" [href]="urls?.badgrUrl">connect your account.</a>
    </p>
  </div>
  <div>
    <p tabIndex="0" id="noCredlyOrBadgrText">Unsure if you have an account with Credly or Badgr?
      <a [id]="idx+'-noCredlyOrBadgrLink'" role="link" aria-label="Credentials FAQ" target="_blank" href="https://help.studentclearinghouse.org/myhub/article-categories/credentials/"> Learn More </a>
    </p>
  </div>
</div>

