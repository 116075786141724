import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Credential, CredentialResponse } from 'src/app/classes/CredentialResponse';
import { WalletCredential } from '../classes/WalletCredential';
import { LearnerDisputeResource } from '../classes/LearnerDisputeReasource';

@Injectable({
  providedIn: 'root'
})
export class StudentDispService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
  };

  GET_CREDENTIALS_URL = '/api/myhub/holder/wallet/credential/provider/NSC';
  DISPUTE_URL = '/api/myhub/holder/wallet/credential/dispute/';
  DISPUTE_DETAILS_URL = '/api/myhub/holder/wallet/credential/dispute/details/';
  GET_DISPUTE_FILE_UPLOAD_URL = '/api/myhub/holder/wallet/credential/dispute/uploadUrl/';
  GET_DISPUTE_FILE_DOWNLOAD_URL = '/api/myhub/holder/wallet/credential/dispute/readUrl/';


  constructor(private http: HttpClient) { }

   getCredentials() {
    return this.http.get<Array<WalletCredential>>(this.GET_CREDENTIALS_URL).pipe(
      map((data: any) => {
        return data;
      })
    );
   }

   getDisputeDetails(disputeId: string) {
    return this.http.get<LearnerDisputeResource>(this.DISPUTE_DETAILS_URL+disputeId);
   }

   cancelDispute(id: string): Observable<any>{
    const dispute = { dispute: false, disputeReason: 'Cancel'};
    return this.http.post<Credential[]>(this.DISPUTE_URL + id, dispute, this.httpOptions).pipe(
      map((updatedCredential: any) => {
        return updatedCredential;
      })
    );
   }

   createDispute(credentialId: string | undefined, disputeReasonVal: string, disputeFileName:string): Observable<any> {
    const dispute = { dispute: true, disputeReason: disputeReasonVal, disputeFileName: disputeFileName};
    return this.http.post(this.DISPUTE_URL + credentialId , dispute, this.httpOptions);
   }

   getPreSignedUrl(fileName: string) : Observable<string> {
    return this.http.get<string>(this.GET_DISPUTE_FILE_UPLOAD_URL+fileName, this.httpOptions).pipe(
      map((data: any) => {
        return data.url;
      })
    );
   }

   uploadfileAWSS3(file: File, fileName: string) {
    var blob = file.slice(0, file.size, file.type);
    var newFile = new File([blob], fileName, {type: file.type});

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': file.type
      }),
    };

    return this.getPreSignedUrl(fileName).pipe(
      switchMap((url:string) => {
        return this.http.put(url, newFile, httpOptions);
      })
    );
  }

  getPreSignedDownloadUrl(fileName: string): Observable<string> {
    return this.http.get<string>(this.GET_DISPUTE_FILE_DOWNLOAD_URL+fileName, this.httpOptions).pipe(
      map((data: any) => {
        return data.url;
      })
    );
   }
}
