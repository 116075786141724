import { cloneDeep as _cloneDeep, filter as _filter, get as _get, trim as _trim } from 'lodash';
import { Injectable } from '@angular/core';
import { Courses, Degrees, StudentDegree, Enrollments, StudentEnrollment, NscStundentData, StudentCredentials, StudentOrgs, OrgDTO, EnrollmentOrgDTO } from '../classes/NSCStudentData';
import { MockData } from './mockdata';
import { BehaviorSubject, of } from 'rxjs';
import * as _ from 'lodash';
import multiSort from '../components/shared/common-table/multi-sort-function';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SchoolProfile } from '../classes/SchoolProfile';
import { CredentialResponse, Credential } from 'src/app/classes//CredentialResponse';

@Injectable({
  providedIn: 'root'
})

export class DataService {
  ferpaFlag= false;
  dataExcludedForMyHubFlag = false;
  credlyAlertGloablFlag=true;
  badgrAlertGloablFlag=true;
  isDataLoaded=false;
  studentData = {} as NscStundentData;
  orgMap = new Map<string, OrgDTO>();
  schoolProfilesMap = new Map<string, SchoolProfile>();
  enrollmentOrgMap = new Map<string, EnrollmentOrgDTO>();
  enrollmentAndDegreeOrgs = new Map<string, EnrollmentOrgDTO>();
  hasEnrollmentError: boolean = false;
  hasDegreeError: boolean = false;
  hasCredentialError: boolean = false;
  hasCourseError: boolean = false;
  credlyRefreshClicked: boolean = false;
  hasUnofficialTS = new BehaviorSubject(false);
  hasLoadedUnofficialTS = new BehaviorSubject(false);
  hasLoadedSchoolProfiles = new BehaviorSubject(false);
  credentials!: Credential[];
  hasLoadedNscCredentials = new BehaviorSubject(false);
  hasDegrees!: boolean;
  smartResumeFeatureToggle = new BehaviorSubject(true);

  constructor(private mockData : MockData,
    private sanitizer: DomSanitizer) { }

  save(newContentObject: any, dataProperty?: any): void {
    const assignTarget = dataProperty ? _get(this.studentData, dataProperty) : this.studentData;
    Object.assign(assignTarget, newContentObject);
    // console.log(_cloneDeep(this.data));
  }

  saveEnrollments(json: any) {
    this.save({
      enrollments: json
    })
    let enrollments = [] as StudentEnrollment[];
    if(this.studentData.enrollments) {
      for (var enrollment of this.studentData.enrollments.studentEnrollments) {
        let orgDTO: OrgDTO | any;
        orgDTO = this.orgMap.get(enrollment.orgCode + enrollment.branchCode);
        //enrollment.orgLogoBase64 = orgDTO.logoBase64;
        //enrollment.orgLogoContentType = orgDTO.contentType;
        enrollment.contactEmail = orgDTO.contactEmail;
        enrollment.contactName = orgDTO.contactName;
        enrollment.enrollmentStatus = this.getEnrollmentStatus(enrollment.enrollmentStatus);
        enrollment.orgName = orgDTO.orgName;
        enrollment.contactEmail = orgDTO.contactEmail;
        enrollment.contactName = orgDTO.contactName;
        if(enrollment.releaseEnrlFerpaEduFlg==='N'){
          this.ferpaFlag=true;
        }
        enrollments.push(enrollment)
      }
      this.studentData.enrollments.studentEnrollments = enrollments;
      console.log("enrollments size:" + enrollments.length);
    }
  }

  saveDegrees(json: Degrees) {
    console.log("saving degrees");
    this.save({
      degrees: json
    })
    let degrees = [] as StudentDegree[];
    for (var degree of this.studentData.degrees.studentDegrees) {
      let orgDTO = {} as OrgDTO | any;
      orgDTO = this.orgMap?.get(degree.orgCode + degree.branchCode);
      console.log("org found orgmap"+ orgDTO?.schoolCode);
      if(!orgDTO?.schoolCode){
        let orgDTO = {} as OrgDTO | any;
        orgDTO.schoolCode= degree.orgCode;
        orgDTO.schoolBranch="00";
        orgDTO.orgName="TEST SCHOOL";
        degree.orgName="SCHOOL - "+ degree.orgCode;
        orgDTO.logoBase64 = this.mockData.noLogoBase64;
        orgDTO.contentType = this.mockData.noLogoBase64Type;
        this.orgMap.set(orgDTO.schoolCode + orgDTO.schoolBranch, orgDTO)
        degrees.push(degree);
        console.log("org has no org data:"+ orgDTO.schoolCode);
        continue;
      }
      degree.contactEmail = orgDTO.contactEmail;
      degree.contactName = orgDTO.contactName;
      degree.orgName = orgDTO.orgName;
      degree.disputeFlag=false;
      //console.log(degree.releaseDegreeFerpaEduFlg);
      if(degree.releaseDegreeFerpaEduFlg==='N'){
        this.ferpaFlag=true;
      }
      if(degree.contactEmail){
        degree.contactInfo='For additional information regarding this degree, please contact '+ orgDTO.contactName+ ' at '+ orgDTO.contactEmail
      }
      degrees.push(degree);
    }
    this.studentData.degrees.studentDegrees = degrees;
    if(degrees?.length) {
      this.hasDegrees = true;
    }
    console.log("degrees size:" + degrees.length);

  }

  saveCourses(json: Courses) {
    this.save({
      courses: json
    })
  }

  isDataExcludedForMyHub(): boolean{
    return this.studentData?.studentOrgs?.generalInfo?.dataExcludedForMyHub;
  }

    getFerpaFlag(): boolean{
    if(this.studentData?.degrees?.studentDegrees) {
      for (var degree of this.studentData?.degrees?.studentDegrees) {
        if(degree.releaseDegreeFerpaEduFlg==='N'){
          return true;
        }
      }
    }
    if(this.studentData?.enrollments?.studentEnrollments) {
      for (var enrollment of this.studentData?.enrollments?.studentEnrollments) {
        if(enrollment.releaseEnrlFerpaEduFlg==='N'){
         return true;
        }
      }
    }
    return false;
  }

  saveStudentOrgs(json: StudentOrgs | any) {
    this.studentData.studentOrgs = json;
    if(!this.studentData || !this.studentData.studentOrgs){
      return;
    }

    if(this.studentData.studentOrgs.generalInfo.dataExcludedForMyHub){
        this.dataExcludedForMyHubFlag = true;
    }
    for (var org of this.studentData.studentOrgs.organizations) {
      let orgDTO = {} as OrgDTO;
      let enrollmentOrgDTO = {} as EnrollmentOrgDTO;
      orgDTO.orgName = org.orgName;
      orgDTO.schoolCode = org.schoolCode;
      if (org.schoolLogo && org.schoolLogo.logoBase64) {
        orgDTO.logoBase64 = org.schoolLogo.logoBase64;
        orgDTO.contentType = org.schoolLogo.contentType;
      }else{
        orgDTO.logoBase64 = this.mockData.noLogoBase64;
        orgDTO.contentType = this.mockData.noLogoBase64Type;
        console.log("org has no logo:"+ orgDTO.schoolCode);
      }
      if (org.contact) {
        orgDTO.contactEmail = org.contact?.email;
        orgDTO.contactName = org.contact?.name;
      }
      orgDTO.participatesInTO = org.participatesInTO;
      orgDTO.toURL = org.toLink;
      orgDTO.schoolBranch = org.schoolBranch;
      this.orgMap.set(orgDTO.schoolCode + orgDTO.schoolBranch, orgDTO)

      //saving enrollment orgs
      enrollmentOrgDTO.orgName = org.orgName;
      enrollmentOrgDTO.schoolCode = org.schoolCode;
      enrollmentOrgDTO.schoolBranch = org.schoolBranch;
      enrollmentOrgDTO.displayAdvancedEnrollment = org.displayAdvancedEnrollment;
      enrollmentOrgDTO.displayCurrentEnrollment = org.displayCurrentEnrollment;
      enrollmentOrgDTO.displayGoodStudentEnrollment = org.displayGoodStudentEnrollment;
      enrollmentOrgDTO.offerEnrollmentCertificates = org.offerEnrollmentCertificates;
      //enrollmentOrgDTO.logoBase64 = orgDTO.logoBase64;
      //enrollmentOrgDTO.contentType = orgDTO.contentType;
      enrollmentOrgDTO.contactName = org.contact?.name;
      enrollmentOrgDTO.contactEmail = org.contact?.email;

      this.enrollmentOrgMap.set(enrollmentOrgDTO.schoolCode + enrollmentOrgDTO.schoolBranch, enrollmentOrgDTO);
    }
    console.log("saving saveStudentOrgs comnpleted");
  }

  saveOrgsEnrollmentsAndDegrees(json: StudentOrgs | any) {
    let studentOrgs = json;
    if(!studentOrgs){
      return;
    }
    for (var org of studentOrgs.organizations) {
      let enrollmentOrgDTO = {} as EnrollmentOrgDTO;
      
      //saving enrollment and degree orgs
      enrollmentOrgDTO.orgName = org.orgName;
      enrollmentOrgDTO.schoolCode = org.schoolCode;
      enrollmentOrgDTO.schoolBranch = org.schoolBranch;
      enrollmentOrgDTO.displayAdvancedEnrollment = org.displayAdvancedEnrollment;
      enrollmentOrgDTO.displayCurrentEnrollment = org.displayCurrentEnrollment;
      enrollmentOrgDTO.displayGoodStudentEnrollment = org.displayGoodStudentEnrollment;
      enrollmentOrgDTO.offerEnrollmentCertificates = org.offerEnrollmentCertificates;
      enrollmentOrgDTO.logo = this.getOrgLogo(enrollmentOrgDTO.schoolCode,enrollmentOrgDTO.schoolBranch);
      enrollmentOrgDTO.participatesInTO = org.participatesInTO;
      enrollmentOrgDTO.toURL = org.toLink;
      enrollmentOrgDTO.contactName = org.contact?.name;
      enrollmentOrgDTO.contactEmail = org.contact?.email;
      if(!this.enrollmentAndDegreeOrgs.has(enrollmentOrgDTO.schoolCode + enrollmentOrgDTO.schoolBranch)) {
        this.enrollmentAndDegreeOrgs.set(enrollmentOrgDTO.schoolCode + enrollmentOrgDTO.schoolBranch, enrollmentOrgDTO);
      }
    }
    console.log("saving saveStudentOrgs for enr and deg comnpleted",this.enrollmentAndDegreeOrgs);
  }

  saveSchoolProfiles(json: any) {
    this.schoolProfilesMap = new Map(Object.entries(json)); //convert json to map
    //add school profiles data to enrollment orgs
    this.enrollmentOrgMap.forEach((org: EnrollmentOrgDTO) => {
      let currSchoolProfile = this.schoolProfilesMap.get(org?.schoolCode + org?.schoolBranch);
      if (currSchoolProfile?.legalNameOnly) {
        org.legalNameOnly = currSchoolProfile?.legalNameOnly;
      } else {
        console.log("legalNameOnly is ", currSchoolProfile?.legalNameOnly, " for ", org.schoolCode);
      }
      if (currSchoolProfile?.allowToOrder !== undefined) {
        org.allowToOrder = currSchoolProfile?.allowToOrder;
      } else {
        console.log("allowToOrder is ", currSchoolProfile?.allowToOrder, " for ", org.schoolCode);
      }
      if (currSchoolProfile?.toDisabledStatement) {
        org.toDisabledStatement = currSchoolProfile?.toDisabledStatement;
      } else {
        console.log("toDisabledStatement is ", currSchoolProfile?.toDisabledStatement, " for ", org.schoolCode);
      }
      if (currSchoolProfile?.offerCEDiploma) {
        org.offerCEDiploma = currSchoolProfile?.offerCEDiploma;
      } else {
        console.log("offerCEDiploma is ", currSchoolProfile?.offerCEDiploma, " for ", org.schoolCode);
      }
    });

    this.enrollmentAndDegreeOrgs.forEach((org: EnrollmentOrgDTO) => {
      let currSchoolProfile = this.schoolProfilesMap.get(org?.schoolCode + org?.schoolBranch);
      if (currSchoolProfile?.allowToOrder !== undefined) {
        org.allowToOrder = currSchoolProfile?.allowToOrder;
      } else {
        console.log("allowToOrder is ", currSchoolProfile?.allowToOrder, " for ", org.schoolCode);
      }
      if (currSchoolProfile?.toDisabledStatement) {
        org.toDisabledStatement = currSchoolProfile?.toDisabledStatement;
      } else {
        console.log("toDisabledStatement is ", currSchoolProfile?.toDisabledStatement, " for ", org.schoolCode);
      }
      if (currSchoolProfile?.offerCEDiploma) {
        org.offerCEDiploma = currSchoolProfile?.offerCEDiploma;
      } else {
        console.log("offerCEDiploma is ", currSchoolProfile?.offerCEDiploma, " for ", org.schoolCode);
      }
    });
    this.hasLoadedSchoolProfiles.next(true);
  }

  saveUnofficialTSOrgs(json: SchoolProfile[] | any[]) {
    this.save({unofficialTSOrgs: json});
    if(this.studentData?.unofficialTSOrgs?.length){
      for (var org of this.studentData.unofficialTSOrgs) {
        if (!org.iconImgbase64) {
          org.iconImgbase64 = this.mockData.noLogoBase64;
        }
        if (!org.iconImageContentType) {
          org.iconImageContentType = this.mockData.noLogoBase64Type;
        }
        console.log("saving saveUnofficialTSOrgs comnpleted");
      }
    }
  }

  saveCredentials(json: StudentCredentials) {
    this.save({
      badgeCredentials: json
    })
  }

  get() {
    // return a read only clone so a controller can't manipulate the data directly;
    return _cloneDeep(this.studentData);
  }

  getCredentialImagesForDisplay(): string[] {
    const imgUrls = [] as string[];
    const enrollments = [] as string[];
    if (this.studentData.badgeCredentials && this.studentData.badgeCredentials.credentials) {
      for (var i = 0; i < 2; i++) {
        imgUrls.push(this.studentData.badgeCredentials.credentials[i]?.imageURL);
        if (imgUrls.length === 2)
          break;
      }
    }
    return imgUrls;
  }

  getDegreeImagesForDisplay(): string[] | SafeResourceUrl[] {
    const imgUrls = [] as SafeResourceUrl[];
    const enrollments = [] as string[];
    if (this.studentData.degrees && this.studentData.degrees.studentDegrees) {

      for (var i = 0; i < this.studentData.degrees.studentDegrees.length; i++) {
        console.log(this.studentData.degrees.studentDegrees[i].orgCode);
        if (!enrollments.includes(this.studentData.degrees.studentDegrees[i].orgCode)) {
          enrollments.push(this.studentData.degrees.studentDegrees[i]?.orgCode);
          const url = this.getOrgLogo(this.studentData.degrees.studentDegrees[i].orgCode, this.studentData.degrees.studentDegrees[i].branchCode);
          imgUrls.push(url);

        }
        if (imgUrls.length === 2)
          break;
      }
    }
    return imgUrls;
  }

  getEnrollmentImagesForDisplay(): string[] | SafeResourceUrl[] {
    const imgUrls = [] as SafeResourceUrl[];
    const enrollments = [] as string[];

    if (this.studentData.enrollments && this.studentData.enrollments.studentEnrollments) {
      for (var i = 0; i < this.studentData.enrollments.studentEnrollments.length; i++) {

        if (!enrollments.includes(this.studentData.enrollments.studentEnrollments[i].orgCode)) {

          enrollments.push(this.studentData.enrollments.studentEnrollments[i]?.orgCode);
          const url = this.getOrgLogo(this.studentData.enrollments.studentEnrollments[i].orgCode, this.studentData.enrollments.studentEnrollments[i].branchCode);
          imgUrls.push(url);
        }
        if (imgUrls.length === 2)
          break;
      }
    }
    return imgUrls;
  }

  getStudentSchoolCds() : string[] {
    const schoolCds = [] as string[];
    if(!this.studentData|| !this.studentData?.studentOrgs || !this.studentData.studentOrgs?.organizations){
      return schoolCds;
    }
    for (var org of this.studentData.studentOrgs.organizations) {
      schoolCds.push(org.schoolCode);
    }
    return schoolCds;
  }

  /* In getLatestUniqueEnrollmentOrgMap method, we are taking an instance of studentEnrollments as enrollments. Then we are setting distinct enrollments into a new latestEnrollments, if two or more enrollments found, then enrollment with latest termBeginDate is set to respective orgCode value in map, Finally we are returning map values spread in an array */
  getLatestUniqueEnrollmentOrgMap() {
    const enrollments = this.studentData.enrollments?.studentEnrollments;
    const enrollmentOrgMap = this.enrollmentOrgMap;
    const latestEnrollments = new Map<string, EnrollmentOrgDTO>();
    if(enrollments?.length) {
      for(let i = 0; i < enrollments.length; i++) {
        let currOrgCodeWithBranch = enrollments[i].orgCode + enrollments[i].branchCode;
        let currOrg = enrollmentOrgMap.get(currOrgCodeWithBranch);
        if(currOrg) {
          if(!latestEnrollments.has(currOrgCodeWithBranch)) {
            currOrg.latestEnrollmentStartDate = enrollments[i].termBeginDate;
            currOrg.latestEnrollmentStatus = enrollments[i].enrollmentStatus;
            currOrg.contactName= enrollments[i].contactName;
            currOrg.contactEmail= enrollments[i].contactEmail;
            latestEnrollments.set(currOrgCodeWithBranch, currOrg);
          }
          if(latestEnrollments.has(currOrgCodeWithBranch)) {
            const currEnrollment = this.enrollmentOrgMap.get(currOrgCodeWithBranch);
            if(currEnrollment) {
              if(enrollments[i].termBeginDate > currOrg.latestEnrollmentStartDate) {
                currOrg.latestEnrollmentStartDate = enrollments[i].termBeginDate;
                currOrg.latestEnrollmentStatus = enrollments[i].enrollmentStatus;
                latestEnrollments.set(currOrgCodeWithBranch, currOrg);
              }
            } else {
              console.log('enrollment not found with orgCode: ',currOrgCodeWithBranch);
            }
          }
        } else {
          console.log('org for enrollment not found: ',currOrg);
        }
      }
    } else { //Orgs for Advanced Registration user
      return [...enrollmentOrgMap.values()];
    }
    //spread map values in an array
    return [...latestEnrollments.values()];
  }

  getEnrollmnetAndDegreeOrgs() {
    return [...this.enrollmentAndDegreeOrgs.values()];
  }

  getEnrollmentStatus(status: string){
    switch(status) {
      case 'H': {
         return 'Half Time';
         break;
      }
      case 'F': {
        return 'Full Time';
        break;
      }
      case 'G': {
        return 'Graduated';
        break;
      }
      case 'L': {
        return 'Less than half-time';
        break;
     }
     case 'W': {
       return 'Withdrawn';
       break;
     }
     case 'A': {
       return 'Leave of absence';
       break;
     }
     case 'D': {
      return 'Deceased';
      break;
    }
     case 'Q': {
      return 'Three Quarter Time';
      break;
    }
    case 'S': {
      return 'sentry /withrawal';
      break;
    }
    default: {
        return 'Undefined';
        break;
    }
   }

  }

  base64ToImageUrl(base64Str?: string, type?: string): SafeResourceUrl {
    if(base64Str && type) {
      return this.sanitizer.bypassSecurityTrustResourceUrl('data:'+type+';base64,'+base64Str);
    } else {
      console.log('incorrect image - type :',type);
      return 0;
    }
  }

  getOrgLogo(schoolCode: string, schoolBranch: string): SafeResourceUrl {
    //debugger;
    const orgDTO = this.orgMap?.get(schoolCode + schoolBranch);
    if(orgDTO) {
      return this.sanitizer.bypassSecurityTrustResourceUrl('data:'+orgDTO?.contentType+';base64,'+orgDTO?.logoBase64);
    } else {
      return this.sanitizer.bypassSecurityTrustResourceUrl('data:'+ this.mockData.noLogoBase64Type+';base64,'+ this.mockData.noLogoBase64);
      return 0;
    }
  }

  getOrgName(schoolCode: string, schoolBranch: string): string {
    //debugger;
    const orgDTO = this.orgMap?.get(schoolCode + schoolBranch);
    if(orgDTO) {
      return orgDTO.orgName;
    } else{
      return schoolCode;
    }
  }

  saveCredlyRefreshClicked(credlyRefreshClicked: boolean) {
    this.credlyRefreshClicked = credlyRefreshClicked;
  }

  getCredlyRefreshClicked(): boolean {
    return this.credlyRefreshClicked;
  }

  getMock() {
    return of(this.mockData.mockData);
  }

  getMockNew() {
    return _cloneDeep(this.mockData.mockDataOld);
  }

  getMockVerificationEvents() {
    return _cloneDeep(this.mockData.verificationEventsMockData);
  }

  getMockVerificationEventDetails() {
    return _cloneDeep(this.mockData.verificationDetailMockData);
  }

  getLoanDefermentEvents() {
    return _cloneDeep(this.mockData.loanDefermentMockData);
  }

  saveNscCredentails(credentails:Credential[]){
    this.credentials = credentails;
    for (var credentail of this.credentials) {
      if(credentail.issuerSchoolCode) {
        credentail.issuerSchoolCode = credentail.issuerSchoolCode.slice(0,6);
      }
      //console.log("credentail.name:"+ credentail.name);
      for (var degree of this.studentData.degrees.studentDegrees) {
        if(credentail.name == degree.degreeTitle){
          degree.disputeFlag = credentail.dispute;
          //console.log("degree.degreeTitle:"+ degree.degreeTitle,degree.disputeFlag);
        }
      }
    }
    let credResponse = {} as CredentialResponse;
    credResponse.credentials = credentails;
    this.save({
      nscCredentials: credResponse
    });
    this.hasLoadedNscCredentials.next(true);
  }

  updateNscCredential(credential:Credential) {
    const index = this.credentials.findIndex(x => x.id === credential.id);
    this.credentials[index] = credential;
    this.saveNscCredentails(this.credentials);
  }

 }
