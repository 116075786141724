import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scholarship-dialogue',
  templateUrl: './scholarship-dialogue.component.html',
  styleUrls: ['./scholarship-dialogue.component.scss']
})
export class ScholarshipDialogueComponent implements OnInit {

  constructor() { }

  scholarshipOwlUrl = 'https://scholarshipowl.com/';

  ngOnInit(): void {
  }

  onClickContinue() {
    window.open(this.scholarshipOwlUrl, '_blank');
  }

}
