import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { SchoolProfile } from '../classes/SchoolProfile';
import { MockData } from './mockdata';
import { ARStudentInfoResponse } from '../classes/ARStudentInfoResponse';
import { StudentBasicInfo } from '../classes/StudentBasicInfo';
import { EnrollmentOrgDTO, OrgDTO } from '../classes/NSCStudentData';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  mockData = new MockData();

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
  };

  sysErr = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  getNSCStudentDataAll() {
    return this.http.get('/api/adsws/rst/studentdetail/ALL', this.httpOptions);
  }

  getNSCStudentEnrollments() {
    //return this.getMockCredentials();
    return this.http.get('/api/adsws/rst/studentdetail/Enrollment', this.httpOptions);
  }

  getNSCStudentDegrees() {
    //return this.getMockCredentials();
    return this.http.get('/api/adsws/rst/studentdetail/Degree', this.httpOptions);
  }

  getNSCStudentCourses() {
    return this.http.get('/api/adsws/rst/studentdetail/Course', this.httpOptions);
  }

  getWalletProfile() {
    console.log("call wallet Profile");
    return this.http.get('/myhub/wallet/profile', this.httpOptions);
  }

  getProfileSchoolInfo(schoolCd:string,branchCd:string): Observable<SchoolProfile> {
    return this.http.get<SchoolProfile>('/myhub/wallet/profile/profileSchool/'+schoolCd+'/'+branchCd, this.httpOptions);
  }

  getProfileSchoolsMap(opeIdList: string[]): Observable<Map<string, SchoolProfile>> {
    return this.http.post<Map<string, SchoolProfile>>('/myhub/wallet/profile/profileSchool/all', opeIdList, this.httpOptions);
  }

  getToPublicSchoolProfiles(schoolCdList: string[]): Observable<EnrollmentOrgDTO[]> {
    return this.http.post<EnrollmentOrgDTO[]>('/api/myhub/student/toPublicSchoolProfiles', schoolCdList, this.httpOptions);
  }

  getStudentBasicInfo(schoolCd: string, branchCd: string): Observable<StudentBasicInfo> {
    return this.http.get<StudentBasicInfo>('/myhub/student/profile/studentBasicInfo/'+ schoolCd + '/' + branchCd, this.httpOptions);
  }

  getARStudentInfo(schoolCd: string, branchCd: string): Observable<ARStudentInfoResponse> {
    return this.http.get<ARStudentInfoResponse>('/api/sssapi/rst/enrollmentcertificate/findStudent/ar/' + schoolCd + '/' + branchCd, this.httpOptions);
  }

  getUnofficialTranscripts(studentProfileId: string): Observable<SchoolProfile[]> {
    //return of(this.mockData.mockUnofficialTSOrgs);
    return this.http.get<SchoolProfile[]>('/myhub/unofficialts/schools', this.httpOptions);
  }

  getFeatureToggles(): Observable<any> {
    return this.http.get('/api/myhub/featureToggle/student', { observe: 'response' });
    // return of(new HttpResponse({
    //   status: 200,
    //   body: [
    //     {
    //       "active": false,
    //       "featureType": null,
    //       "createdDate": null,
    //       "updatedDate": null,
    //       "createdBy": null,
    //       "updatedBy": null,
    //       "featureName": "Disputes"
    //     }
    //   ]
    // }));
  }

  getMockCredentials() {
    //return of(this.mockData.mockDataOld);
  }
}
