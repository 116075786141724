import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'credly-badgr-login',
  templateUrl: './credly-badgr-login.component.html',
  styleUrls: ['./credly-badgr-login.component.scss']
})
export class CredlyBadgrLoginComponent implements OnInit {

  @Input() urls!: any;
  @Input() idx: string | undefined;

  constructor() { }

  ngOnInit(): void {
    if(!localStorage.getItem("credly_new_user")){
      localStorage.setItem("credly_new_user","false");
    }
  }

  redirectToCredlyForRefresh() {
    localStorage.setItem("credly_new_user", "true");
    window.location.href = this.urls?.credlyUrl;
  }

}
