<div class="dialog-wrapper">
  <h2 id="schshipDialogTitle" mat-dialog-title>
    You are being redirected to another page
  </h2>
  <mat-dialog-content class="mat-typography">
    <p id="schshipDialogContent" tabIndex="0">
      <b style="color: #742C85;">
        Select "Continue" to leave this National Student Clearinghouse site and go to ScholarshipOwl.com, which matches you with potential scholarships.
      </b>
      <br>
      ScholarshipOwl.com is not powered by National Student Clearinghouse, and it has its own separate Terms of Use and Privacy Policy. Any information you provide to ScholarshipOwl.com is subject to those terms.
    </p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button id="schshipDialogContinueBtn" mat-stroked-button color="primary" (click)="onClickContinue()" mat-dialog-close>Continue</button>
    <button id="schshipDialogCancelBtn" mat-raised-button color="primary" cdkFocusInitial mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</div>
