export const const_routes = {
  MYRECORDS: 'myrecords',
  DATACORRECTION: 'datacorrection',
  SELFSERVICE: 'selfservice',
  DASHBOARD: 'dashboard',
  PAGENOTFOUND: 'pagenotfound',
  VERIFICATION: 'verification',
  ACCESS_DENIED: 'accessdenied',
  SMART_RESUME: 'smartresume'
};

export const const_colors = {
  MYHUB_COLOR: '#742c85',
  COLOR_WHITE: '#FFF'
}

export const const_pages = {
  DASHBOARD: 'dashboard',
  MYRECORDS: 'my_records',
  SELFSERVICE: 'self_service',
  VERIFICATION: 'verification',
  DATACORRECTION: 'data_correction'
}


export const page_title = {
  DASHBOARD: 'Myhub - Dashboard',
  MYRECORDS: 'Myhub - My Records',
  SELFSERVICE: 'Myhub - Self Service',
  VERIFICATION: 'Myhub - Verification Events',
  DATACORRECTION: 'Myhub - Data Correction'
}
