import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { interval, Subscription, timer } from 'rxjs';
import { OktaAuthService } from 'student-ui-sso-integration-lib';
import { TimeoutService } from 'ts-ui-components';

@Component({
  selector: 'app-timeout-dialogue',
  templateUrl: './timeout-dialogue.component.html',
  styleUrls: ['./timeout-dialogue.component.scss']
})
export class TimeoutDialogueComponent implements OnInit {

  timerSub$ !: Subscription;
  onInitTime!: number;
  endSessionDuration: number = 2*60*1000;

  constructor(private timeoutService: TimeoutService,
    private dialogRef: MatDialogRef<TimeoutDialogueComponent>) { }

  ngOnInit(): void {
    this.onInitTime = new Date().getTime();
    this.timerSub$ = timer(this.endSessionDuration).subscribe(() => {
      //console.log('timer called');
      this.logout();
    });
  }

  logout() {
    this.timerSub$.unsubscribe();
    this.timeoutService.endSession();
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.timerSub$.unsubscribe();
  }

  @HostListener('window:focus',[])
  onWindowFocus() {
    const currTime = new Date().getTime();
    console.log('on focus window time diff : ',(currTime - this.onInitTime)/1000);
    if(currTime - this.onInitTime > this.endSessionDuration) {
      this.logout();
    }
  }
}
