import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'student-ui-sso-integration-lib';
import { CommonTableComponent } from './common-table/common-table.component';
import { SampleTablesComponent } from './sample-tables/sample-tables.component';
import { CredlyBadgrLoginComponent } from './credly-badgr-login/credly-badgr-login.component';
import { SystemErrorComponent } from './system-error/system-error.component';
import { InfoBoxComponent } from './info-box/info-box.component';



@NgModule({
  declarations: [
    CommonTableComponent,
    SampleTablesComponent,
    CredlyBadgrLoginComponent,
    SystemErrorComponent,
    InfoBoxComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule
  ],
  exports: [
    AngularMaterialModule,
    ReactiveFormsModule,
    CommonTableComponent,
    SampleTablesComponent,
    CredlyBadgrLoginComponent,
    SystemErrorComponent,
    InfoBoxComponent
  ]
})
export class SharedModule { }
