import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';

// tgz header footer from node_modules
import { HeaderModule, SidebarEventEmitterService, SidebarNavModule, SidebarService, StudentUiSSOIntegrationLibModule } from 'student-ui-sso-integration-lib';
import { OktaAuthService } from 'student-ui-sso-integration-lib';

import { DialogTimeoutModule, TimeoutService, BlockingIndicatorService, BlockingIndicatorModule, MaterialModule } from 'ts-ui-components';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRootComponent } from './components/app-root/app-root.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SharedModule } from './components/shared/shared.module';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ScholarshipDialogueComponent } from './components/scholarship-dialogue/scholarship-dialogue.component';
import { TimeoutDialogueComponent } from './components/timeout-dialogue/timeout-dialogue.component';
import { GradescaleDialogueComponent } from './components/my-records/components/gradescale-dialogue/gradescale-dialogue.component';
import { StudentDispService } from './services/student-disp.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DashboardDisputesToggleOffComponent } from './components/dashboard-disputes-toggle-off/dashboard-disputes-toggle-off.component';

@NgModule({
  declarations: [
    AppRootComponent,
    DashboardComponent,
    PageNotFoundComponent,
    ScholarshipDialogueComponent,
    TimeoutDialogueComponent,
    GradescaleDialogueComponent,
    DashboardDisputesToggleOffComponent


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    StudentUiSSOIntegrationLibModule,
    BlockingIndicatorModule,
    HttpClientModule,
    MatTableModule,
    MatTabsModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    OverlayModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    SharedModule,
    SidebarNavModule,
    HeaderModule
  ],
  providers: [
    OktaAuthService,
    BlockingIndicatorService,
    SidebarEventEmitterService,
    SidebarService,
    StudentDispService,
    {provide: MAT_DATE_LOCALE, useValue: 'en-US'}
  ],
  bootstrap: [AppRootComponent]
})
export class AppModule { }
