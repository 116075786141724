import { Component, Input, OnInit } from '@angular/core';
import { SidebarService } from 'student-ui-sso-integration-lib';

@Component({
  selector: 'app-system-error',
  templateUrl: './system-error.component.html',
  styleUrls: ['./system-error.component.scss']
})
export class SystemErrorComponent implements OnInit {

  @Input() idx: string | undefined;

  constructor(private sidenavService: SidebarService) { }

  ngOnInit(): void {
    //we are hiding default sidebar to avoid navigation when error is displayed,
    //this.sidenavService.hideSidebar();
  }

}
