<div class="nsc-app-root" [hidden]="!isLoggedIn">
    <!-- Routed Feature -->
    <div *ngIf="useNSCHeader" class="w-100">
      <lib-header headerValue="Myhub" rootUrl ="/education" appColor="#742C85"></lib-header>
    </div>

    <div class="d-flex flex-row flex-grow-1">
      <div class="side-nav">
        <!-- Sidebar from ui lib -->
        <lib-sidebar-nav *ngIf="sidebarConfig" [config]="sidebarConfig" style="min-height: 100%;"></lib-sidebar-nav>
      </div>
      <div class="w-100 flex-grow-1">
        <router-outlet></router-outlet>
      </div>
      <div class="nodata-container" *ngIf="sysError">
        <app-system-error idx="appRoot"></app-system-error>
      </div>
    </div>

    <!--Footer-->
    <div class="w-100"><lib-footer></lib-footer></div>
</div>

